export default function () {
  return {
    title: "Задание на проектирование",
    description:
      'объект, предусмотренных в рамках проекта "Разработка ТЭО проекта "Усиление схемы внешнего электроснабжения г.Алматы. Строительство электросетевых объектов""',
    data: [
      {
        key: "Наименование проекта строительства",
        value:
          'Разработка ТЭО проекта "Усиление схемы внешнего электроснабжения г.Алматы. Строительство электросетевых объектов"',
      },
      { key: "Стадия проектирования", value: "ТЭО" },
      {
        key: "Вид строительства",
        value: "Значение Lorem ipsum dolor sit amet consectetur adipisicing.",
      },
      {
        key: "Основание для разработки ПСД",
        value: "",
      },
      {
        key: "Цель разработки",
        value: "Значение Lorem ipsum dolor sit amet consectetur adipisicing.",
      },
      {
        key: "Выделение комплексов строительства",
        value: "",
      },
      {
        key: "Расчет стоимости строительства",
        value: "",
      },
      {
        alone: true,
        key: 'Раздел "Наименование раздела"',
      },
      {
        key: "Задание на разработку",
        value: "Значение Lorem ipsum dolor sit amet consectetur adipisicing.",
      },
      {
        key: "Спецификации оборудования и материалов (Технические требования)",
        value: "Значение Lorem ipsum dolor sit amet consectetur adipisicing.",
      },
    ],
  };
}
